export const useSeo = (seoInput) => {
  const i18nHead = useLocaleHead()
  const setI18nParams = useSetI18nParams()
  const { t, locale } = useI18n()
  const route = useRoute()
  const localeRoute = useLocaleRoute()

  const runtimeConfig = useRuntimeConfig()

  // Se il parametro seo non è reattivo lo rendo reattivo in modo da uniformare il codice
  const seo = isRef(seoInput) ? seoInput : ref(seoInput)
  const meta = seo.value?.meta || []
  const link = seo.value?.link || []

  if (seoInput?.market) {
    const locales = ['it', 'en', 'en-us']
    const entryLocales = seoInput?.market.map((entry) => entry.slug)
    const missingLocales = locales.filter(
      (locale) => !entryLocales.includes(locale)
    )
    const missingHrefLang = missingLocales.map((locale) => {
      // Lasctio l'elencone perché si capisce se ottimizzo qua sotto poi non si capisce che fa
      if (locale === 'it' && !missingLocales.includes('en')) {
        return [
          {
            id: 'i18n-alt-it',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'en')?.path
            }`,
            hreflang: 'it',
          },
          {
            id: 'i18n-alt-it-IT',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'en')?.path
            }`,
            hreflang: 'it-IT',
          },
        ]
      }
      if (locale === 'it' && !missingLocales.includes('en-us')) {
        return [
          {
            id: 'i18n-alt-it',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'en-us')?.path
            }`,
            hreflang: 'it',
          },
          {
            id: 'i18n-alt-it-IT',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'en-us')?.path
            }`,
            hreflang: 'it-IT',
          },
        ]
      }

      if (locale === 'en' && !missingLocales.includes('en-us')) {
        return [
          {
            id: 'i18n-alt-en',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'en-us')?.path
            }`,
            hreflang: 'en',
          },
          {
            id: 'i18n-alt-en-GB',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'en-us')?.path
            }`,
            hreflang: 'en-GB',
          },
        ]
      }
      if (locale === 'en' && !missingLocales.includes('it')) {
        return [
          {
            id: 'i18n-alt-en',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'it')?.path
            }`,
            hreflang: 'en',
          },
          {
            id: 'i18n-alt-en-GB',
            rel: 'alternate',
            href: `${runtimeConfig.public.baseURL}${
              localeRoute(route, 'it')?.path
            }`,
            hreflang: 'en-GB',
          },
        ]
      }

      if (locale === 'en-us' && !missingLocales.includes('en')) {
        return {
          id: 'i18n-alt-en-US',
          rel: 'alternate',
          href: `${runtimeConfig.public.baseURL}${
            localeRoute(route, 'en')?.path
          }`,
          hreflang: 'en-US',
        }
      }
      if (locale === 'en-us' && !missingLocales.includes('it')) {
        return {
          id: 'i18n-alt-en-US',
          rel: 'alternate',
          href: `${runtimeConfig.public.baseURL}${
            localeRoute(route, 'it')?.path
          }`,
          hreflang: 'en-US',
        }
      }
    })

    link.push(...missingHrefLang.flat())
  }

  // Se i parametri localeParams sono settati hreflang e canonical prendono gli slug tradotti
  /*
  {
    it: { slug: 'slug-1'},
    en: { slug: 'slug-2'},
    en-us: { slug: 'slug-3'}
  }
  */
  if (seo.value?.localeParams) {
    setI18nParams(seo.value?.localeParams)
  }

  const title =
    `${seo.value?.title} | ${runtimeConfig.public.siteName} ${t(
      `bucket.market.${locale.value}`
    )}` || ''

  return useHead({
    title,
    meta: [
      ...i18nHead.value.meta,

      ...meta.map((meta) => {
        if (meta.name === 'description') {
          const description = `${meta.content} ${t(
            `bucket.seoDescriptionDiscover`
          )} ${runtimeConfig.public.siteName} ${t(
            `bucket.market.${locale.value}`
          )}`

          return [
            {
              ...meta,
              content: description,
            },
            {
              property: 'og:description',
              content: description,
            },
          ]
        } else {
          return meta
        }
      }),
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'og:url',
        content: `${runtimeConfig.public.baseURL}${route.fullPath}`,
      },
      {
        property: 'og:image',
        content: `${runtimeConfig.public.baseURL}/assets/img/og-image-zava.jpg`,
      },
    ].flat(),
    bodyAttrs: { ...i18nHead.value.htmlAttrs },
    link: [...i18nHead.value.link, ...link],
  })
}
